import React from "react";
import Page from "../components/Page";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"
import CTA from "../components/Cards/CTA";

export default function Studentflak({ data }) {
  const title = "Studentskyltar för 2023 - Allt om studentplakat";
  return (
    <>
      <Page title={title}>
      <div className="py-3 bg-gray-50"><p className="flex max-w-screen-xl mx-auto items-center px-4 sm:px-8 justify-between w-full text-gray-700 text-sm md:text-base">Artikeln innehåller reklam genom annonslänkar.</p></div>

        <article className="container px-5 py-6 mx-auto info-content">
          <GatsbyImage image={data.image.childImageSharp.gatsbyImageData} className="mb-5 rounded-xl" alt="utspring på hulebäcksgymnasiet" />
          <h1 className="text-4xl text-gray-900 font-bold md:text-5xl">
            {title}
          </h1>
          <p>När studenten närmar sig med stormsteg så är det en tid för förberedelser och en viktig sak som man behöver förbereda är studentskylten.</p>
          <p>Men med så många alternativ tillgängliga för att skapa en studentskylt, från att välja en design online till att skapa en helt egen från grunden, så kan det vara svårt att veta var man ska börja.</p>
          <p>Men oroa dig inte - i denna artikel kommer vi att besvara alla dina frågor kring studentskyltar och studentplakat så att du kan känna dig självsäker och nöjd med ditt val.</p>
          <h2 id="b-sta-butikerna-f-r-att-k-pa-studentplakat">Bästa butikerna för att köpa studentplakat</h2>
          <p>När det är dags för studenten är en studentskylt ett måste för att fira och visa upp sin prestation. Men var ska du köpa din studentskylt?</p>
          <p>Här är de bästa butikerna för att köpa studentplakat:</p>
          <ul>
            <li><strong>Smartphoto</strong> har många olika mallar att välja mellan och erbjuder laminerat posterpapper för sina skyltar. Deras skyltar levereras med regnskydd och storleken är 50x70 cm.</li>
            <li><strong>Önskefoto</strong> erbjuder studentplakat och de har över 20 olika mallar att välja mellan. Deras skyltar är dubbelsidiga och regntåliga med pinne, och storleken är 50x60 cm.</li>
            <li><strong>Partykungen</strong> erbjuder snabb leverans på 1-3 dagar och har 14 olika mallar att välja mellan. Storleken på deras skyltar är 50x70 cm, och de är också dubbelsidiga.</li>
            <li><strong>Fotoklok</strong> erbjuder 44 olika mallar att välja mellan och deras skyltar är hela regntåliga. Deras skyltar är också dubbelsidiga och storleken är 50x70 cm.</li>
          </ul>
          <p>Bland dessa olika butiker så rekommenderar vi <a href="https://to.smartphoto.se/t/t?a=1065483635&amp;as=1773817588&amp;t=2&amp;tk=1&amp;url=https://www.smartphoto.se/studenten/studentskylt" rel="nofollow" target={"_blank"}>Smartphoto</a> på grund av sin hög kvalité på studentplakat och bra priser. Deras plakat är bland annat laminerat vilket gör det extra hållbart.</p>
          <p>Det var också hos Smartphoto som vi beställde studentskylt för min egna examen när jag tog studenten för några år sedan.</p>
          <CTA header="Dags att ordna studentskylt?" subHeader="Designa och beställ din studentskylt under 5 min." link={"https://to.smartphoto.se/t/t?a=1065483635&as=1773817588&t=2&tk=1&url=https://www.smartphoto.se/studenten/studentskylt"} />
          <h2 id="hur-mycket-kostar-en-studentskylt-">Hur mycket kostar en studentskylt?</h2>
          <p>Att beställa en färdig studentskylt från nätet kostar vanligtvis mellan 300 och 400 kronor, medan att göra en egen studentskylt från grunden brukar hamna på ett pris cirka 300 kronor.</p>
          <p>Detta gör att om du vill göra din egna studentskylt så gör du det mer för att göra det mer personligt, snarare en att spara pengar.</p>
          <p>Om du funderar på att beställa ett färdigt studentplakat så har Smartphoto ett av de bästa priserna för 349 kronor till hög kvalité.</p>
          <h2 id="vad-ska-man-skriva-p-studentplakatet-">Vad ska man skriva på studentplakatet?</h2>
          <p>På ett studentplakat brukar man vanligtvis skriva studentens namn och vilken klass studenten tillhör. Man brukar också skriva något i stil med &quot;Grattis till studenten!&quot; för att fira det stora ögonblicket.</p>
          <p>Utöver detta kan man också välja att skriva med årtalet då personen tog studenten om man väljer att spara sin studentskylt. Det kan vara ett bra sätt att minnas tillbaka till studentåret i framtiden.</p>
          <p>Om du även vill vara riktigt kreativ och göra ditt studentplakat mer personligt kan du lägga till ett citat, budskap eller ett personligt meddelande till den utexaminerade på deras studentskylt.</p>
          <CTA header="Dags att ordna studentskylt?" subHeader="Designa och beställ din studentskylt under 5 min." link={"https://to.smartphoto.se/t/t?a=1065483635&as=1773817588&t=2&tk=1&url=https://www.smartphoto.se/studenten/studentskylt"} />
          <h2 id="vilken-bild-ska-du-v-lja-f-r-studentplakatet-">Vilken bild ska du välja för studentplakatet?</h2>
          <p>Att välja rätt bild till studentplakatet kan vara en utmaning. Men som en grundregel brukar man välja en bild från när studenten var mellan 2-6 år gammal.</p>
          <p>En bild där studenten är lite grisig och exempelvis har mat eller lera i ansiktet brukar vara vanligt. Det är inte nödvändigt att bilden är på detta vis, men en rolig och minnesvärd bild brukar sätta guldkant på studentplakatet.</p>
          <p>Om du inte har någon bild från när studenten var 2-6 år kan du välja en annan bild, men se till att den är av god kvalitet och passande för studentskylten.</p>
          <p>När du väljer bilden bör du också tänka på hur den passar ihop med studentplakatets layout och design. En del föredrar exempelvis en bild som matchar färgerna i bakgrunden eller som på något sätt kompletterar skylten.</p>
          <GatsbyImage image={data.image2.childImageSharp.gatsbyImageData} className="rounded-xl" alt="bild på utspring från Mölnlycke" />
          <h2 id="hur-g-r-man-en-egen-studentskylt-">Hur gör man en egen studentskylt?</h2>
          <p>Om du vill göra ditt studentplakat mer personligt så kan det vara en bra idé att göra det helt själv från grunden. För att göra detta så kan man antingen köpa ett färdigt kit för att göra en studentskylt eller så kan man köpa alla saker var för sig.</p>
          <p>Om du vill göra ett studentplakat för studenten så behöver du:</p>
          <ul>
            <li><strong>Vit kartong eller affischbräda</strong></li>
            <li><strong>Rejäl pinne att hålla i</strong></li>
            <li>
              <strong>
                Ett foto på den utexaminerade studenten
              </strong>
              </li>
            <li><strong>En sax</strong></li>
            <li><strong>Lim</strong></li>
            <li><strong>Tejp</strong></li>
            <li><strong>Bra pennor</strong></li>
            <li><strong>Linjal</strong></li>
            <li><strong>Kniv</strong></li>
          </ul>
          <p>Alla dessa sakerna går att hitta på exempelvis Biltema eller Clas Ohlson.</p>
          <p>Att göra en egen studentskylt från grunden har däremot både sina fördelar och nackdelar. En fördel är exempelvis att du kan vara mer kreativ och personlig i designen och du kan spara lite pengar genom att köpa dina material separat och göra det själv.</p>
          <p>En nackdel med att göra en egen studentskylt är att det kan ta tid och kräva lite kreativitet och teknisk skicklighet. Du behöver köpa material, planera utseendet och utforma själva studentplakatet helt själv.</p>
          <h3 id="hur-stor-ska-studentplakatet-vara-">Hur stor ska studentplakatet vara?</h3>
          <p>En studentskylt är vanligtvis 50 cm bred och 70 cm hög, men om du väljer att göra en egen studentskylt från grunden har du möjlighet att skapa en som är så stor som du vill.</p>
          <p>Om du däremot gör din egna studentskylt så kan du också göra den hur stor som helst.</p>
          <p>Tänk doc på att ju större studentskylten är, desto tyngre blir den och därmed svårare att hålla upp. Det finns också en större risk att studentskylten går sönder om den är orimligt stor. </p>
          <CTA header="Dags att ordna studentskylt?" subHeader="Designa och beställ din studentskylt under 5 min." link={"https://to.smartphoto.se/t/t?a=1065483635&as=1773817588&t=2&tk=1&url=https://www.smartphoto.se/studenten/studentskylt"} />
          <h2 id="m-ste-man-ha-en-studentskylt-">Måste man ha en studentskylt?</h2>
          <p>I Sverige är studentskyltar nästintill obligatoriska, men om du verkligen känner att du inte har råd eller om du inte kan motivera kostnaden, kan du naturligtvis välja att inte ha en.</p>
          <p>Studentskyltar kan ses lite som en tradition i Sverige och det ses som ett sätt att gratulera studenten till sin examen och det fyller även funktionen att det bli enklare för studenten att hitta till sin familj senare i den stora folkmassan.</p>
          <p>Om du väljer att inte ha en studentskylt så är det bra att bestämma sig för en plats på gården där ni och den utexaminerade studenten ska träffas efter utspringet. Det kan också vara bra att förklara för studenten varför ni väljer att inte ha en studentskylt.</p>
          <h3 id="varf-r-finns-studentskyltar-">Varför finns studentskyltar?</h3>
          <p>Traditionen med studentskyltar har varit en viktig del av svenska studenters examensceremoni under de senaste 50 åren.</p>
          <p>Det ses i allmänhet som ett sätt att gratulera studenten till examen och det tjänar också till att göra det lättare för studenten att hitta sin familj senare i den stora folkmassan.</p>
          <p>Utöver dessa funktioner finns det däremot ingen mer särskilda skäl för studentskyltar. De har bara blivit en tradition och en del av den svenska studentkulturen.</p>
          <p>De flesta studentskyltarna brukar även kastas bort efter student, vilket gör de inte riktigt går att motivera utefter miljöperspektivet.</p>

        </article>
      </Page>
    </>
  );
}


export const pageQuery = graphql`
  query {
    image: file(relativePath: { eq: "studentskyltar.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    image2: file(relativePath: { eq: "utspring1.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;
